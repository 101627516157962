<template>
  <div class="shwoPolicyCon">
    <header class="banner"></header>
    <!-- 展示政策 -->
    <div class="def-container searchCondidtion">
      <el-form ref="elForm" :model="form" size="small" label-width="150px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="行业类别">
              <el-select
                v-model="form.sysCategory"
                multiple
                clearable
                placeholder="请选择行业类别"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dictData.hyleList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业规模">
              <el-select
                clearable
                v-model="form.enterpriseSize"
                placeholder="请选择企业规模"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dictData.qygmList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业属性">
              <el-select
                clearable
                v-model="form.enterpriseAttributes"
                placeholder="请选择企业属性"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dictData.qysxList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="成长周期">
              <el-select
                clearable
                v-model="form.growthCycle"
                placeholder="请选择成长周期"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dictData.czzqList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产业类别">
              <el-select
                clearable
                v-model="form.sysEnterType"
                placeholder="请选择产业类别"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dictData.cyleList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册要求">
              <el-input
                v-model="form.requireRegist"
                placeholder="请输入注册要求"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纳税地">
              <el-input
                v-model="form.paymentPlace"
                placeholder="请输入纳税地"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业注册类型">
              <el-select
                clearable
                v-model="form.sysEnterpriseRegistertype"
                placeholder="请选择企业注册类型"
                style="width: 100%"
              >
                <el-option
                  v-for="dict in dictData.qyzclxList"
                  :key="dict.key"
                  :label="dict.label"
                  :value="dict.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主营业务收入">
              <el-input
                v-model="form.mainbusinessIncome"
                placeholder="请输入主营业务收入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="上一年度企业所得税">
              <el-input
                v-model="form.lastIncomeTax"
                placeholder="请输入上一年度企业所得税(单位：万元)"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24" align="center">
            <el-button type="primary" round class="matchBtn" @click="startMatch"
              >开始匹配</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="def-container" style="background: #fff; margin-bottom: 20px">
      <div class="def-container middleSearch">
        <!-- <span class="pageTit">政策<em>文件</em></span>
        <div class="middleSearchInput">
          <el-input placeholder="请输入内容" v-model="input"> </el-input>
          <i class="el-icon-search" @click="toSearch"></i>
        </div> -->
        <span class="result"
          >为您找到
          <span style="color: #f28b11">{{ total }}</span> 个相关信息</span
        >
      </div>
      <div style="display: flex; justify-content: space-evenly; padding: 20px">
        <!-- <div class="leftMenu">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :name="0">
              <template slot="title">
                <div class="item" @click="clickLi(0)">全部</div>
              </template>
              <div class="sub_item">
                <el-radio-group
                  v-model="radioAll"
                  size="medium"
                  v-for="item in labelData"
                  :key="item.labelname"
                  @change="(val) => radioChange(val, 'radioAll')"
                  @click.native.prevent="radioClick(item.labelname)"
                >
                  <el-radio-button :label="item.labelname"></el-radio-button>
                </el-radio-group>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div> -->
        <div>
          <el-table
            v-loading="loading"
            :data="tableData"
            class="tableStyle"
            :show-header="false"
            @row-click="toPolicyDetail"
            height="360px"
          >
            <el-table-column label="" show-overflow-tooltip align="center">
              <template slot-scope="scope">
                <div style="cursor: pointer">
                  <div class="flex-center row1">
                    <!-- <i class="titleIcon"></i> -->
                    <p style="font-size: 18px; color: #333">
                      <i class="titleIcon"></i
                      ><span v-show="scope.row.deptName"
                        >[{{ scope.row.deptName }}]</span
                      >{{
                        scope.row.title ||
                        scope.row.name ||
                        scope.row.policyName
                      }}
                    </p>
                  </div>
                  <div class="flex-center row2">
                    <span class="flex-center">
                      <i class="timeIcon"></i>
                      <span class="fc">{{ scope.row.publictime }}</span>
                    </span>
                    <span class="flex-center">
                      <i class="themeIcon"></i>
                      <span class="fc">{{ scope.row.fwzh }}</span>
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-pagination
            v-show="total"
            style="margin-top: 10px"
            background
            :page-size="pageSize"
            layout="prev, pager, next,total, jumper"
            prev-text="上一页"
            next-text="下一页"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="CurrentChange"
          >
          </el-pagination> -->
        </div>
        <!-- <div class="rightMenu"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
const tableData = [];
import { mapGetters } from "vuex";
import {
  FilterGroup,
  // FilterRadio,
  FilterCheckBoxMine,
  FilterItem,
} from "./components/filter/index.js";
export default {
  name: "showPolicy",
  components: {
    // FilterGroup,
    // FilterRadio,
    // FilterCheckBoxMine,
    // FilterItem,
  },
  props: {},
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
      getDictLabel: "dictionary/getDictLabel",
    }),
  },
  data() {
    return {
      loading:false,
      form: {
        sysCategory:"",
        enterpriseSize:"",
        enterpriseAttributes:"",
        growthCycle:"",
        sysEnterType:"",
        requireRegist:"",
        paymentPlace:"",
        sysEnterpriseRegistertype:"",
        mainbusinessIncome:"",
        lastIncomeTax:"",
      },
      dictData: {
        hyleList: [], //行业类别
        qygmList: [], //企业规模
        qysxList: [], //企业属性
        czzqList: [], //成长周期
        cyleList: [], //产业类别
        qyzclxList: [], //企业注册类型
      },
      labelList: [], //标签名
      total: 0,
      pageSize: 15,
      checkListDq: [],
      labelData: [],
      activeNames: [],
      radioKeyWord: "",
      radioKeyWordData: [
        { name: "企业所得税" },
        { name: "行政部门" },
        { name: "税务局" },
      ],
      radioAll: "",
      radioTaxKind: "",
      radioYear: "",
      isActive: 0,
      menu: ["全部", "按税种查询", " 按年份查询"],
      all: [
        "税费政策",
        "人才支持",
        "知识产权",
        "营商环境",
        "民营经济",
        "房租减免",
      ],
      taxKind: ["增值税", "消费税", "企业所得税", "个人所得税"],
      year: ["2021", "2020", "2019", "2018"],
      tableData,
      input: "",
      // 搜索条件
      searchInfo: {
        data: {
          hotTag: "", //热门标签
          xmlb: "", // 项目类别
          cylb: "", // 产业类别
          deptcode: "", // 发布部门
          zcfs: "", // 支持方式
          temp_amount: "", // 资金限额
          zzxeleft: "", // 起始资金限额
          zzxeright: "", // 结束资金限额
          name: "", // 事项名称
          hyle: "", //行业类别
          zcfl: "", //政策分类
          dq: "", //地区
          qysx: "", //企业属性
          qygm: "", //企业规模
          growth: "", //成长周期
        },
        oldParams: {},
        deptList: [], // 职能部门列表
        amountList: [
          { value: "0", label: "1000万及以上" },
          { value: "1", label: "500万~1000万" },
          { value: "2", label: "100万~500万" },
          { value: "3", label: "100万及以下" },
          // { value: '4', label: '自定义' },
        ],
        areaList: [], //地区列表
        type: "0", // 搜索类型{'0':传统列表, '1':数字化列表}
      },
      searchDataHyle: "", //搜索行业类别需要的数据
      searchDataZcfl: "", //搜索政策分类需要的数据
      searchDataDq: "", //搜索地区需要的数据
      searchDataDept: "", //搜索部门需要的数据
      searchDataQysx: "", //搜索企业属性需要的数据
      searchDataQygm: "", //搜索企业规模需要的数据
      searchDataGrowth: "", //搜索成长周期需要的数据
      areaValue: [], //政策级别名称
      enterpriseAttributesValue: [], //企业属性
      enterpriseSizeValue: [], //企业规模
      growthCycleValue: [], //成长周期
      sysCategoryValue: [], //行业类别
      deptName: [], //主管部门需要传的参数数据
      hydlList: [], //主管部门
      policyClassificationValue: [], //政策分类需要传的参数数据
      projectData:[],//政策关联的项目列表
      matchList:[],//匹配的项目列表
      itemList:[],//条件匹配成功的所有项目列表
    };
  },
  created() {
    this.getDict();
    this.getDefaultInfo();
    this.getPolicy(10,1,'start');
    this.getDeptList();
    // this.getProjectLibrary();
    this.getAllLabel();
    //this.queryLabelDept();
    this.getBqHotList();
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sq_policy_xmlb",
        "sys_enter_type",
        "sq_policy_zjlx",
        "permit_sertype", // 办理对象
        "sys_enterprise_registertype", // 注册类型
        "sys_category", // 行业类别(国民经济分类)
        "policy_level", //政策层级
        "enterprise_attributes", //企业属性
        "enterprise_size", //企业规模
        "growth_cycle", //成长周期
      ],
    });
  },
  mounted() {},
  methods: {
    // 根据政策id查项目
    async getProject(id) {
      let url = "/dev-api/business/sq-apply/selectProjectList";
      let params = {
        policyId: id,
        pageSize: 100,
        pageNum: 1,
        serachProject: "",
      };
     await this.$httpApi
          .post(url, params)
          .then((res) => {
            this.projectData = res.list;
          })
          .catch();
    },
    // 开始匹配
    startMatch(){
      this.getPolicy(10,1);
    },
    getPolicy(pageSize,pageNum,isStart) {
      this.loading = true;
      let pageS = pageSize?pageSize:6;
      let pageN = pageNum?pageNum:1;
      // ?pageSize=${pageS}&pageNum=${pageN}
      let url = `/dev-api/policy/evaluation/recommendPolicy`;
      let params = {
        sysCategory: this.form.sysCategory,
        enterpriseSize: this.form.enterpriseSize,
        enterpriseAttributes: this.form.enterpriseAttributes,
        growthCycle: this.form.growthCycle,
        sysEnterType: this.form.sysEnterType,
        requireRegist: this.form.requireRegist,
        paymentPlace: this.form.paymentPlace,
        sysEnterpriseRegistertype: this.form.sysEnterpriseRegistertype,
        mainbusinessIncome: this.form.mainbusinessIncome,
        lastIncomeTax: this.form.lastIncomeTax,
      };
      if(isStart){
        params['shxydm'] = this.userInfo.tyshxydm;
      }else {
        params['shxydm'] = '';
      }
      this.$httpApi.post(url,params).then(res=>{
        this.loading = false;
        // 数据对象数组去重
        let result = [];
        let obj = {};
        let arr = res.data.list;
        for(let i =0; i<arr.length; i++){
        if(!obj[arr[i].id]){
          result.push(arr[i]);
          obj[arr[i].id] = true;
          }
        }
        this.tableData = result;
        this.total = result.length || res.data.qb || res.data.total || 0;

        this.itemList = res.data.itemList;
      })
    },
    // 获取当前企业用户的行业类别、产业类别等
    getDefaultInfo() {
      let url = "/dev-api/enterprise/baseInfo/list";
      let params = {
        pageSize: 10, // 页大小
        pageNum: 1, // 页码
        shxydm: this.userInfo.tyshxydm,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          let tempForm = res.rows[0].logoMogoId;
          let temp = JSON.parse(tempForm);
          this.form = temp;
        })
        .catch();
    },
    getDict() {
      this.getlist("sys_category"); //行业类别
      this.getlist("sys_enter_type"); //产业类别
      this.getlist("sys_enterprise_registertype"); //企业注册类型
      this.getlist("enterprise_attributes"); //企业属性
      this.getlist("enterprise_size"); //企业规模
      this.getlist("growth_cycle"); //成长周期
    },
    getlist(urlid) {
      let that = this;
      that.$httpApi
        .get(`/dev-api/system/dict/getDictListByType?dictType=${urlid}`, {})
        .then((res) => {
          if (res.code == 200) {
            let name = "";
            switch (urlid) {
              case "sys_category":
                name = "hyleList";
                break;
              case "sys_enter_type":
                name = "cyleList";
                break;
              case "sys_enterprise_registertype":
                name = "qyzclxList";
                break;
              case "enterprise_attributes":
                name = "qysxList";
                break;
              case "enterprise_size":
                name = "qygmList";
                break;
              case "growth_cycle":
                name = "czzqList";
                break;
              default:
                break;
            }
            this.dictData[name] = res.data;
            // 企业规模筛选掉不限选项
            if (name == "qygmList") {
              this.dictData.qygmList = res.data.filter((item) => {
                return item.key != "6";
              });
            }
          } else {
            return [];
          }
        })
        .catch((err) => {});
    },
    cpu_paramsList() {
      let list = [];
      // 行业类别
      // if (this.searchDataHyle) {
      //   let arrData = [];
      //   let listlabel = [];
      //   let listvalue = [];
      //   if (this.searchDataHyle !== "") {
      //     arrData = this.searchDataHyle.split(",");
      //     arrData.forEach((item) => {
      //       listvalue.push(item);
      //       let label = this.getDictList("sys_category").find(
      //         (cv) => cv.key === item
      //       )?.label;
      //       listlabel.push(label);
      //       //this.sysCategoryValue.push(label)
      //     });
      //     list.push({
      //       type: "hyle",
      //       value: listvalue.join("，"),
      //       label: listlabel.join("，"),
      //     });
      //   }
      // }
      // // 政策分类
      // if (this.searchDataZcfl) {
      //   let arrData = [];
      //   let listlabel = [];
      //   let listvalue = [];
      //   if (this.searchDataZcfl !== "") {
      //     arrData = this.searchDataZcfl.split(",");
      //     arrData.forEach((item) => {
      //       listvalue.push(item);
      //       let label = this.zcflList.find((cv) => cv.key === item)?.label;
      //       listlabel.push(label);
      //     });
      //     list.push({
      //       type: "zcfl",
      //       value: listvalue.join(","),
      //       label: listlabel.join(","),
      //     });
      //   }
      // }
      // 企业属性
      // if (this.searchDataQysx) {
      //   let arrData = [];
      //   let listlabel = [];
      //   let listvalue = [];
      //   if (this.searchDataQysx !== "") {
      //     arrData = this.searchDataQysx.split(",");
      //     arrData.forEach((item) => {
      //       listvalue.push(item);
      //       let label = this.getDictList("enterprise_attributes").find(
      //         (cv) => cv.key === item
      //       )?.label;
      //       listlabel.push(label);
      //       // this.enterpriseAttributesValue.push(label)
      //     });
      //     list.push({
      //       type: "qysx",
      //       value: listvalue.join("，"),
      //       label: listlabel.join("，"),
      //     });
      //   }
      // }
      // 企业规模(我是)
      // if (this.searchDataQygm) {
      //   let arrData = [];
      //   let listlabel = [];
      //   let listvalue = [];
      //   if (this.searchDataQygm !== "") {
      //     arrData = this.searchDataQygm.split(",");
      //     arrData.forEach((item) => {
      //       listvalue.push(item);
      //       let label = this.getDictList("enterprise_size").find(
      //         (cv) => cv.key === item
      //       )?.label;
      //       listlabel.push(label);
      //       // this.enterpriseSizeValue.push(label)
      //     });
      //     list.push({
      //       type: "qygm",
      //       value: listvalue.join("，"),
      //       label: listlabel.join("，"),
      //     });
      //   }
      // }
      // 成长周期
      // if (this.searchDataGrowth) {
      //   let arrData = [];
      //   let listlabel = [];
      //   let listvalue = [];
      //   if (this.searchDataGrowth !== "") {
      //     arrData = this.searchDataGrowth.split(",");
      //     arrData.forEach((item) => {
      //       listvalue.push(item);
      //       let label = this.getDictList("growth_cycle").find(
      //         (cv) => cv.key === item
      //       )?.label;
      //       listlabel.push(label);
      //       // this.growthCycleValue.push(label)
      //     });
      //     list.push({
      //       type: "growth",
      //       value: listvalue.join("，"),
      //       label: listlabel.join("，"),
      //     });
      //   }
      // }
      // 政策级别
      if (this.searchDataDq) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataDq !== "") {
          arrData = this.searchDataDq.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.getDictList("policy_level").find(
              (cv) => cv.key === item
            )?.label;
            listlabel.push(label);
            //this.areaValue.push(label)
          });
          list.push({
            type: "dq",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      //主管部门
      if (this.searchDataDept) {
        let arrData = [];
        let listlabel = [];
        let listvalue = [];
        if (this.searchDataDept !== "") {
          arrData = this.searchDataDept.split(",");
          arrData.forEach((item) => {
            listvalue.push(item);
            let label = this.hydlList.find(
              (cv) => cv.deptId === item
            )?.deptName;
            listlabel.push(label);
            //this.searchDataDeptList.push(label);
          });
          list.push({
            type: "zgbm",
            value: listvalue.join("，"),
            label: listlabel.join("，"),
          });
        }
      }
      // 去掉第一个逗号
      list.filter((item) => {
        if (item.label[0] == "，") {
          item.label = item.label.substring(1);
        }
      });
      this.cleanPolicyParams();
      list.forEach((item) => {
        switch (item.type) {
          case "hyle":
            this.sysCategoryValue.push(item.label);
            break;
          case "qysx":
            this.enterpriseAttributesValue.push(item.label);
            break;
          case "qygm":
            this.enterpriseSizeValue.push(item.label);
            break;
          case "growth":
            this.growthCycleValue.push(item.label);
            break;
          case "dq":
            this.areaValue.push(item.label);
            break;
          case "zcfl":
            this.policyClassificationValue.push(item.label);
            break;
          case "zgbm":
            this.deptName.push(item.label);
            break;
          default:
            break;
        }
      });
      this.getProjectLibrary();
      return list;
    },
    toSearch() {
      this.getProjectLibrary();
    },
    // 清空传参
    cleanPolicyParams() {
      this.areaValue = [];
      this.enterpriseAttributesValue = [];
      this.enterpriseSizeValue = [];
      this.growthCycleValue = [];
      this.sysCategoryValue = [];
      this.policyClassificationValue = [];
      this.deptName = [];
    },
    // 查询政策文件
    getProjectLibrary() {
      let url = "/elastic/search/sq-policy";
      let params = {
        pageNum: 1,
        pageSize: this.pageSize,
        keyWords: this.input,
        areaValue: this.areaValue,
        enterpriseAttributesValue: this.enterpriseAttributesValue,
        enterpriseSizeValue: this.enterpriseSizeValue,
        growthCycleValue: this.growthCycleValue,
        sysCategoryValue: this.sysCategoryValue,
        policyClassificationValue: this.policyClassificationValue,
        deptName: this.deptName,
        supportWayValue: [],
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          console.log(res.data);
          that.total = res.total;
          that.tableData = res.rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 查询标签
    getAllLabel() {
      let url = "/dev-api/enterprise-label/queryAllLabel";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.labelData = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    // 获取热词标签
    getBqHotList() {
      let url = "/dev-api/sqzd/policyhot/bqHotlist";
      let that = this;
      let params = {};
      that.$httpApi
        .get(url, params)
        .then((res) => {
          // that.radioKeyWordData = res.rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 根据标签查询政策
    getTagPolicy() {
      let url = "/dev-api/enterprise-label/queryPolicyByLabel";
      let params = {
        policyKeywordList: [],
        labelList: this.labelList,
        deptList: [],
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          that.tableData = res.data;
          that.total = res.data.length;
        })
        .catch((e) => {});
    },
    changeSearch(label, value) {
      // 特殊情况不需要触发搜索
      switch (label) {
        case "行业类别":
          this.searchDataHyle = value;
          break;
        case "政策分类":
          this.searchDataZcfl = value;
          break;
        case "政策级别":
          // case "我要找":
          // value = value.substring(1);
          this.searchDataDq = value;
          break;
        case "主管部门":
          this.searchDataDept = value;
          break;
        // case "企业属性":
        //   this.searchDataQysx = value;
        //   break;
        // case "我是":
        case "企业规模":
          this.searchDataQygm = value;
          break;
        // case "成长周期":
        //   this.searchDataGrowth = value;
        //   break;
        default:
          break;
      }
      this.cpu_paramsList();
    },
    clickLi(index) {
      this.isActive = index;
    },
    handleSizeChange() {},
    // 当前页改变时
    CurrentChange(pageNum) {
      let pageSize = this.pageSize;
      let url = "/elastic/search/sq-policy";
      let params = {
        pageNum: pageNum,
        pageSize: pageSize,
        keyWords: this.keywords,
        areaValue: this.areaValue,
        enterpriseAttributesValue: this.enterpriseAttributesValue,
        enterpriseSizeValue: this.enterpriseSizeValue,
        growthCycleValue: this.growthCycleValue,
        sysCategoryValue: this.sysCategoryValue,
        policyClassificationValue: this.policyClassificationValue,
        deptName: this.deptName,
      };
      let that = this;
      that.$httpApi
        .post(url, params)
        .then((res) => {
          console.log(res.data);
          that.total = res.total;
          that.tableData = res.rows;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /**
     * @description 查询职能部门列表
     */
    // 主管部门
    getDeptList() {
      let url = "/system/dept/zList";
      this.$httpApi
        .get(url, { priority: "1" })
        .then((res) => {
          // console.log('getDeptList:', res)
          if (res.data && !!res.data.length) {
            this.hydlList = res.data || [];
          }
        })
        .catch((err) => {
          console.log("getDeptList error:", res);
        });
    },
    // 主管部门
    queryLabelDept() {
      let url = "/dev-api/enterprise-label/queryLabelDept";
      let params = {};
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.hydlList = res.data;
        })
        .catch((err) => {
          console.log("evaluate error:", err);
        });
    },
    async toPolicyDetail(row) {
      //根据id查询相关项目
      await this.getProject(row.id);
      // 政策下的匹配成功的项目
     const matchList =  this.projectData.filter(obj1 => this.itemList.some(obj2 => obj1.id === obj2.xmId));
      // this.userInfo
      let data = JSON.stringify(row);
      sessionStorage.setItem("policyDetailData", data);
      sessionStorage.setItem("matchList", JSON.stringify(matchList));
      // this.$router.push({
      //   name: "showPolicyDetail",
      // });
      this.$router.push({ path: '/showPolicyDetail', query: { key: true }   });
    },
    handleChange(val) {
      console.log(val);
    },
    cleanParams() {
      this.searchDataHyle = "";
      this.searchDataZcfl = "";
      this.searchDataDq = "";
      this.searchDataDept = "";
      this.searchDataQysx = "";
      this.searchDataQygm = "";
      this.searchDataGrowth = "";
      // 清空选择的条件
      this.$refs.FilterCheckBox1.cleanItem();
      this.$refs.FilterCheckBox2.cleanItem();
      this.$refs.FilterCheckBox3.cleanItem();
      this.$refs.FilterCheckBox4.cleanItem();
      // this.$refs.FilterCheckBox5.cleanItem();
      this.cleanPolicyParams();
    },
    radioChange(val, param) {
      switch (param) {
        case "radioAll":
          this.labelList = [];
          let labelKey = "";
          this.labelData.forEach((item) => {
            if (item.labelname == val) {
              labelKey = item.labelcode;
            }
          });
          this.labelList.push({
            labelKey: labelKey,
            labelValue: val,
          });
          this.getTagPolicy();
          break;
        case "radioTaxKind":
          break;
        case "radioYear":
          break;
        case "radioKeyWord":
          break;
        default:
          break;
      }
    },
    radioClick(val) {
      if (val == this.radioAll) {
        this.radioAll = "";
        this.getProjectLibrary();
      } else {
        this.radioAll = val;
        this.labelList = [];
        let labelKey = "";
        this.labelData.forEach((item) => {
          if (item.labelname == val) {
            labelKey = item.labelcode;
          }
        });
        this.labelList.push({
          labelKey: labelKey,
          labelValue: val,
        });
        this.getTagPolicy();
      }
    },
    keywordClick(e) {
      e.target;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@/styles/global.scss";
$width: 1200px;
.fs {
  font-size: 18px;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
// .def-container {
//   width: 1200px;
//   margin: 0 auto;
// }
.banner {
  width: 100%;
  height: 180px;
  background-image: url("~@/assets/deyangImg/policyMatch.png");
  background-size: cover;
  background-position-x: center;
  background-repeat: no-repeat;
}
.shwoPolicyCon {
  width: 100%;
  min-height: 1000px;
  background-color: #eff8ff;
  .searchCondidtion {
    min-height: 200px;
    background-color: #fff;
    padding-top: 40px;
    .matchBtn {
      cursor: pointer;
      width: 200px;
      margin-bottom: 40px;
    }
  }
  .middleSearch {
    height: 60px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    .pageTit {
      padding-left: 28px;
      background: url("~@/assets/images/pageTitBg.png") no-repeat 9px center;
      height: 60px;
      line-height: 60px;
      font-style: italic;
      font-size: 24px;
      font-weight: bold;
      margin-right: 10px;
      em {
        font-weight: bold;
        font-style: italic;
        color: red;
      }
    }
    .middleSearchInput {
      margin-left: 15px;
      position: relative;
      ::v-deep .el-input__inner {
        width: 227px;
        height: 32px;
        border: 1px solid #eee;
        outline: none !important;
        border-radius: 20px !important;
        font-size: 18px;
      }
      i {
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 10px;
        color: #0e97ff;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .result {
      font-size: 18px;
      position: absolute;
      right: 36px;
    }
  }
  .leftMenu {
    width: 164px;
    min-height: 300px;
    .item {
      width: 100%;
      line-height: 20px;
      padding: 15px 0;
      padding-left: 38px;
      color: #fff;
      border-bottom: 1px solid #fff;
      margin-bottom: 5px;
      font-size: 18px;
      background: #0e97ff url("~@/assets/images/showPolicy/xwdt_liOn.png")
        no-repeat 16px center;
      cursor: pointer;
      transition: all 0.3s;
    }
    .on {
      background: #0e97ff url("~@/assets/images/showPolicy/xwdt_liOn.png")
        no-repeat 16px center;
      color: #ffffff;
    }
    .item:hover {
      background: #0e97ff url("~@/assets/images/showPolicy/xwdt_liOn.png")
        no-repeat 16px center;
      color: #ffffff;
      transition: all 0.3s;
    }
    .subMenu {
      width: 100%;
      min-height: 100px;
      background-color: green;
    }
    .sub_item {
      width: 100%;
      height: 400px;
      margin: 5px 0;
      overflow: hidden;
      overflow-y: auto;
      // background-color: red;
    }
    .sub_item::-webkit-scrollbar {
      width: 6px;
    }
    // .sub_item::-webkit-scrollbar-track {
    //   background-color: red;
    //   border-radius:20px;
    // }
    .sub_item::-webkit-scrollbar-thumb {
      height: 80px;
      background-color: #ccc;
      border-radius: 20px;
    }
    ::v-deep {
      .el-radio-button__inner {
        width: 140px !important;
        font-size: 18px;
        padding: 10px;
      }
    }
  }
  .tableStyle {
    width: 1100px;
    &::-webkit-scrollbar {
      display: none;
    }
    .titleIcon {
      margin-right: 10px;
      display: inline-block;
      width: 14px;
      height: 16px;
      background: #fff url("~@/assets/images/showPolicy/title_icon.png");
    }
    .timeIcon,
    .viewIcon,
    .themeIcon {
      display: inline-block;
      width: 14px;
      height: 14px;
    }
    .timeIcon {
      background: url("~@/assets/images/showPolicy/timeIcon.png");
    }
    .viewIcon {
      width: 17px;
      background: url("~@/assets/images/showPolicy/viewIcon.png");
    }
    .themeIcon {
      background: url("~@/assets/images/showPolicy/themeIcon.png");
    }
    .row1 {
      margin: 10px 0;
    }
    .row2 > span {
      margin-right: 20px;
    }
    .fc {
      color: #999;
      margin: 0 10px;
    }
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    display: none;
  }
  .rightMenu {
    width: 240px;
    .rightMenuItem {
      background-color: #f1f8ff;
      width: 240px;
      height: 100px;
      padding: 18px 0 0 25px;
      margin-bottom: 10px;
      cursor: pointer;
      color: #333;
      .fb {
        font-weight: bold;
      }
    }
    .keywordItem {
      margin: 10px auto;
      padding-left: 5px;
      .dot {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #333;
        margin-right: 10px;
      }
    }
    .item1 {
      background: url("~@/assets/images/showPolicy/listPic03.jpg") no-repeat;
    }
    .item2 {
      background: url("~@/assets/images/showPolicy/listPic05.png") no-repeat;
    }
    .node {
      width: 21px;
      height: 23px;
      // background-color: red;
      background: url("~@/assets/images/showPolicy/组 757.png") no-repeat;
    }
    .line {
      width: 90px;
      height: 1px;
      background: #eee;
    }
  }
}
::v-deep {
  //  .el-pagination {
  //     padding-left: 150px;
  //   }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    // background-color: red;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333;
  }
  .el-radio-button--medium .el-radio-button__inner {
    background-color: #eee;
    border-radius: 30px;
    margin: 5px 10px;
    border: none;
    font-size: 18px;
    text-align: center;
  }
  .el-radio-button.is-active .el-radio-button__inner {
    // background-color: red;
  }
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border: none;
    box-shadow: none;
  }
  .el-radio-button__inner:hover {
    // color: red;
  }
  // 折叠面板的
  .el-collapse-item__arrow {
    margin: 0 -9px 0 auto;
    font-weight: 300;
    margin-left: -17px;
    color: #fff;
  }
  .el-collapse-item__header {
    margin-bottom: 10px;
  }
  .filterItem-label {
    font-size: 18px;
  }
  .filter-item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .filterItem-content .content-item {
    font-size: 18px;
  }
  // 头部搜索条件样式
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    // background-color: red;
    box-shadow: none !important;
    border: none !important;
  }
  .el-checkbox-button__inner {
    font-size: 18px;
    color: #333;
  }
  .filterItem-right span {
    font-size: 18px;
    // color: red;
  }
  .filterItem-right .el-icon-caret-bottom,
  .FilterRadio-right .el-icon-caret-bottom {
    // color: red !important;
  }
  .filterItem-label {
    color: #333 !important;
  }
  .el-collapse {
    border-bottom: none;
  }
}
</style>
